import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SACouponsService {
  // live
  baseUrl = 'https://ceocreators-sacoupons-api.azurewebsites.net/api/';

  // staging
  //baseUrl = 'https://livelife-sacoupons-api-staging.azurewebsites.net/api/';

  // local
  //baseUrl = 'https://localhost:5001/api/';
  selectedCoupons: string[];

  constructor(
    private http: HttpClient) { }

    // gets the retailer list
    getRetailers() {
      return this.http.get(`${this.baseUrl}retailers`);
    };

    // gets a specific retailer list
    // TODO: Lok at caching this
    getRetailer(retailerId) {
      return this.http.get(`${this.baseUrl}retailers/${retailerId}`);
    };

    // gets the coupon categories
    getCouponCategories(retailerId) {
      return this.http.get(`${this.baseUrl}couponcategories/${retailerId}`);
    };

    // gets the retailer list
    getCategory(retailerId, categoryId) {
      return this.http.get(`${this.baseUrl}couponcategories/${retailerId}/${categoryId}`);
    };

    // gets the coupon list from the middleware api
    getCouponsByCategory(categoryId) {
      return this.http.get(`${this.baseUrl}?categoryId=${categoryId}`);
    };

    // gets the coupon list by category
    getCouponList(categoryId) {
      return this.http.get(`${this.baseUrl}coupons/getCouponList/${categoryId}`);
    };

    // gets the coupon list by category
    getCoupon(couponId, retailerId) {
      return this.http.get(`${this.baseUrl}coupons/getCoupon/${couponId}/${retailerId}`);
    };

    // creates a basket
    startOpenBasket(memberId, retailerId) {
      return this.http.get(`${this.baseUrl}baskets/startOpenBasket/${memberId}/${retailerId}`);
    };

    // gets the coupon list by category
    getOpenBasket(memberId, retailerId) {
      return this.http.get(`${this.baseUrl}baskets/getOpenBasket/${memberId}/${retailerId}`);
    };

    // gets a claimed basket
    getBasket(memberId, basketId, retailerId) {
      return this.http.get(`${this.baseUrl}baskets/getBasket/${memberId}/${basketId}/${retailerId}`);
    };

    // gets the coupon list by category
    getBasketHistory(memberId, retailerId) {
      return this.http.get(`${this.baseUrl}baskets/getBasketHistory/${memberId}/${retailerId}`);
    };

    // checks out the open basket and clears it
    checkoutOpenBasket(memberId, retailerId) {
      return this.http.get(`${this.baseUrl}baskets/checkoutOpenBasket/${memberId}/${retailerId}`);
    };

    // adds the coupon to the basket
    addCouponToBasket(memberId, couponId, retailerId) {
      return this.http.get(`${this.baseUrl}baskets/addCouponToOpenBasket/${memberId}/${couponId}/${retailerId}`);
    };

    // adds the coupon to the basket
    removeCoupon(memberId, couponId, retailerId) {
      return this.http.get(`${this.baseUrl}baskets/removeCoupon/${memberId}/${couponId}/${retailerId}`);
    };

    // collects the coupon for the user
    collectCoupon(userId, couponId) {
      return this.http.get(`${this.baseUrl}collectcoupon/${userId}/${couponId}`);
    };

    // collects the coupon for the user
    getUserCoupons(userId) {
      return this.http.get(`${this.baseUrl}getusercoupons/${userId}`);
    };

}
